.BackToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}
