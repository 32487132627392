.ImageInput {
  input {
    display: none;
  }

  .custom-file-label {
    position: relative;
    width: 100%;
    cursor: pointer;
    z-index: 0;

    &:after {
      z-index: 0;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .form-control.is-invalid ~ .custom-file-label {
    border: 1px solid #dc3545;
  }

  .invalid-feedback {
    margin-top: -10px;
  }
}
