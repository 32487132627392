.card {
  width: 25px;
  height: 180px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  transition: transform 300ms;
  transform: translateY(0);
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.5);
  color: inherit;
  text-decoration: none;
  background: sienna;
  background: #edd2b0;
  background: radial-gradient(
    #edd2b0 0%,
    #e9cba5 3%,
    #f6d8b2 9%,
    #eed3b0 13%,
    #f3d5af 17%,
    #f5dbb8 20%,
    #efd0a6 24%,
    #f2d4ae 28%,
    #e9cba5 30%,
    #f2d4ae 33%,
    #eeca9d 36%,
    #e6c7a1 41%,
    #f4d6b0 46%,
    #f2d4ae 50%,
    #efd0a9 54%,
    #f3d5af 59%,
    #f2d4ae 62%,
    #e9cba5 66%,
    #e9cba5 69%,
    #efd0a6 74%,
    #f4d6b0 77%,
    #e9cba5 82%,
    #e9cba5 89%,
    #efcfa4 92%,
    #f2d4ae 96%
  );
  background-size: 80px 800px;
  background-position: -20px -120px;

  &:hover {
    transform: translateY(-30px);
    box-shadow: 0px 5px 10px gold;
  }
}

.cardHand {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  &.transformRight {
    transform-origin: bottom right;
  }

  &.transformLeft {
    transform-origin: bottom left;
  }
}

.title {
  margin-bottom: 150px;
  color: white;
}

.number {
  transform: rotate(90deg) rotateY(180deg);
}

.buttonContainer {
  margin-top: -120px;
}
