.FileInput {
  .custom-file-label {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.is-invalid label {
    border-color: #dc3545;
  }

  &.invalid-feedback {
    display: block;
  }
}
