.ChampionshipRegistration {
    .link {
        border: none;
        background: none;
        color: blue;
        text-decoration: underline;

        &:focus, &:active {
            outline: none;
        }
    }
    
    .or-requirement {
        margin-left: 1.1rem;
    }

    .and-requirement {
        margin-left: 1rem;
    }
}