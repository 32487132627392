.TableItem {
  &.selected {
    background-color: orange;

    &:hover {
      background-color: rgba(255, 165, 0, 0.75);
    }
  }

  .td-select-checkbox {
    input {
      zoom: 1.4;
    }
  }
}
