.ToastContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .inner-container {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
  }

  .toast {
    min-width: 300px;
  }
}
