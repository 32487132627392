.nanduModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.nanduModal .nanduModalContent {
  max-width: 500px;
  position: relative;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
  background-color: #fefefe;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.nanduModal .nanduModalClose {
  position: absolute;
  top: -1%;
  right: 1%;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}
.nanduModal .nanduModalClose:hover,
.nanduModal .nanduModalClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.nanduModal .nanduModalSelect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.nanduModal .nanduModalSelectItem {
  width: 100%;
  position: relative;
}
.nanduModal .nanduModalSelectItem .formSelect {
  position: relative;
}
.nanduModal .select-dropdown {
  margin: 20px 0;
}
.nanduModal .nanduModalWarning {
  color: #c50e0e;
}