.nanduSection {
  width: 100%;
  display: block;
}
.nanduSection .nanduInputWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30px;
}
.nanduSection .addNandu {
  font-size: 36px;
  color: gray;
  height: 33px;
  width: 32px;
  line-height: 0;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  border-radius: 20px;
}
.nanduSection .addNandu:hover {
  color: #3c3b3b;
  background-color: white;
}
.nanduSection .break {
  background-color: #e4e4e4;
  margin-top: 30px;
  height: 1px;
  border: 0;
  width: 20%;
}
.nanduSection .title h2 {
  font-weight: bold;
}