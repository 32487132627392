.nanduFormSelect {
  position: relative;
  min-width: 30%;
}
.nanduFormSelect .select-dropdown {
  text-align: left;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nanduFormSelect .dropdown-content {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  margin: 0;
  width: 100%;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  z-index: 1;
  will-change: width, height;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.nanduFormSelect .dropdown-content li {
  clear: both;
  color: rgba(0,0,0,0.87);
  cursor: pointer;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
  text-transform: none;
}
.nanduFormSelect .dropdown-content li>span {
  background-color: transparent;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 10px 16px;
}
.nanduFormSelect .dropdown-content li>span:hover {
  background-color: rgba(0,0,0,0.06);
}
.nanduFormSelect .option-label {
  position: absolute;
  top: -20px;
  font-size: .8rem;
  color: #9e9e9e;
  left: 0;
  height: 100%;
  cursor: text;
  transition: transform .2s ease-out;
  transform-origin: 0% 100%;
  text-align: initial;
  transform: translateY(12px);
  pointer-events: none;
}
.nanduFormSelect .caret {
  color: initial;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 10px;
  margin: auto 0;
  font-size: 10px;
  line-height: 10px;
}