.TrelloCard {
  .card {
    background-color: white;
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 1px 2px grey;

    &:hover {
      background-color: #ffffff80;
    }

    .top-row {
      .badge {
        max-height: 18px;
        color: white;
      }
    }

    .bottom-row {
      font-size: 10px;

      .gender {
        text-transform: capitalize;
      }
    }
  }
}
