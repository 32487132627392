.ResultCard {
  .event-item {
    font-size: 1.2rem;
    margin-bottom: 25px;

    .event-name {
      text-decoration: underline;
    }

    .group-content h6 {
      font-size: 0.9rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .event-item-label {
      font-size: 100%;
      font-weight: bold;

      &.badge {
        background-color: #eff2f5;
      }

      &.gold {
        background-color: #ffd601;
      }

      &.silver {
        background-color: #c0bfc0;
      }

      &.bronze {
        background-color: #b8895c;
      }
    }

    .video-link {
      font-size: 75%;
      text-decoration: underline;
      cursor: pointer;
      color: blue;
    }
  }
}
