.Checkout {
  .card-body {
    padding: 15px;
  }

  .skinny-group-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  h4, h5 {
    margin: 0;
  }
}