.Checkbox {
  &.disabled {
    input {
      filter: invert(90%) hue-rotate(0deg) brightness(3);

      &:hover {
        cursor: not-allowed;
      }
    }

    label:hover {
      cursor: not-allowed;
    }
  }
}
