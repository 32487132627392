@import 'src/vars.scss';

.Participant {
  .name {
    margin-bottom: 0;
    display: inline-block;
  }

  .card-header {
    background-color: $baseBackgroundColor;
    color: $base;
    cursor: pointer;
  }

  .tab-content {
    padding-top: 20px;
  }
}
