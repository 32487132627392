.AdminHome {
  background-color: #faf8fb;
  min-height: 100vh;
  display: flex;
}

.AdminHome-content {
  background-color: #faf8fb;
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 3rem;
  flex-basis: 100%;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
  min-height: 1px;
}