.EventList {
  .title {
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
  }

  .group-member-text-input {
    padding-right: 0;
  }

  .group-member-button-wrapper {
    padding-left: 0;

    .group-member-button {
      height: calc(1.5em + 0.75rem + 5px);
      border-radius: 6px;
      width: 100%;
    }
  }
}
