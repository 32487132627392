@import "./vars.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 15px;

  .btn {
    border: none;

    font-size: 14px;
    font-weight: 600;

    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 14px;
    border-radius: 25px;
    display: inline-block;
    transition: all 0.4s ease 0s;

    &:hover {
      -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      transition: all 0.4s ease 0s;
    }

    &.btn-primary {
      color: $base;
      background: $basePrimaryButtonColor;
    }

    &.btn-danger {
      color: $base;
      background: $baseDangerButtonColor;
    }

    &.btn-success {
      color: $base;
      background: $baseSuccessButtonColor;
    }

    &.btn-warning {
      color: $base;
      background: $baseWarningButtonColor;
    }
  }
}

li,
ul {
  list-style: none;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.print-only,
.print-only * {
  display: none !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .print-only,
  .print-only * {
    display: block !important;
  }
}
