.printNanduForm {
  padding: 40px 30px;
  max-width: 1086px;
  margin: auto;
}
.printNanduForm .printNanduFormOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.printNanduForm .back-link {
  text-align: left;
  margin-bottom: 20px;
}
.printNanduForm .back-link a {
  color: blue;
}
.printNanduForm .userInfoInputGroup {
  display: inline-block;
  width: 100%;
}
.printNanduForm .userInfoInputGroup.small{
  width: 49%;
}
@media screen and (min-width: 500px) {
  .printNanduForm .userInfoInputGroup {
    width: 49%;
  }
  .printNanduForm .userInfoInputGroup.small{
    width: 24.25%;
  }
}
.printNanduForm .userInfoInputGroup.big{
  width: 100%;
}
.printNanduForm .userInfoInput {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.printNanduForm .actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.printNanduForm .action {
  padding: 10px;
  width: 250px;
  max-width: 33%;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #039be5;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  border: 0;
  border-radius: 0;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.printNanduForm .action:hover {
  background-color: #29b6f6;
}