@import 'src/vars.scss';

.Header {
  position: fixed;
  background: $baseThemeColor;
  min-height: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  .nav-link {
    color: white;
    font-weight: bold;

    &:hover {
      background-color: white;
      color: black;
      cursor: pointer;
    }
  }

  .navbar-toggler-container {
    padding-left: 0;
  }

  .ChaneLanguageButton {
    margin-left: 15px;
  }

  .Header-logo {
    max-height: 2.5rem;
  }

  @media (max-width: 992px) {
    .nav-link {
      text-align: center;
    }
  }
}
