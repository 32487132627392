.CardTab {
  display: flex;
  align-items: center;

  .nav-link {
    color: white;
    background-color: #054a91;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    &.active,
    &:hover {
      background-color: #ae2b15;
      color: white;
    }
  }
}
