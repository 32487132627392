.CardCollapse {
  .card-title {
    display: inline-block;
    font-size: 1rem;
  }

  .card-header {
    cursor: pointer;
    background-color: #1a629e;
    color: white;
  }

  .tab-content {
    // TODO: dont use bootstrap rows, they cause scrollbars when unwanted
    overflow-x: auto;
  }
}
