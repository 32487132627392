@import '../../vars.scss';

.Register {
  padding: 10rem 0;
  text-align: center;
  max-width: 350px;

  button {
    width: 100%;
    background: $baseThemeColor;
  }
}