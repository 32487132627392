.JudgingEventCard {
  position: relative;

  .sidebar-container {
    width: 19%;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  .video-container {
    margin-left: 21%;
    width: 80%;
    min-height: 600px;

    video {
      max-height: 600px;
    }
  }

  .list-group-item {
    padding: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .age-title {
    text-align: center;
  }

  .gender-group {
    margin-bottom: 0.5rem;
  }
}
