.nanduScores {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 250px;
}
.nanduScores .score {
  background-color: #c50e0e;
  padding: 10px;
  color: white;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 65px;
  box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.2);
  margin: 5px;
}
.nanduScores .maxed {
  background-color: #35b500;
}
.nanduScores .validation-sign {
  margin-left: 10px;
}