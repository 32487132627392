.nanduInput {
  color: white;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #039be5;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  border: 0;
  border-radius: 0;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.nanduInput.connection {
  background-color: #35b500;
}
.nanduInput.connection:hover {
  background-color: #4fd418;
}
.nanduInput:hover {
  background-color: #29b6f6;
}
.nanduInput .nanduInputRemove {
  color: #fefefe;
  width: 20%;
  height: 20%;
  position: absolute;
  top: 0;
  right: 0;
}
.nanduInput .nanduInputRemove svg {
  fill: red;
}
.nanduInput .code {
  font-weight: bold;
}
.nanduInput .score {
  font-size: 13px;
}