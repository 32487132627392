.Table {
  .table-container {
    overflow: auto;
  }

  .adminTable:hover {
    cursor: pointer;
  }

  .form-group {
    text-align: left;
  }

  .filters .form-group {
    flex: 1 1 0px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .Checkbox {
    margin-bottom: 0;
  }

  .search {
    flex: 1 1 0px;
    margin-right: 5px;
  }

  .page-item.active {
    z-index: 0;
  }

  .pagination {
    overflow: auto;
  }

  td,
  th {
    padding: 0.3rem;
    border-top: 1px solid #bec0c1;
  }
}
