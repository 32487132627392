.GroupEventList {
  .group-member-text-input {
    padding-right: 0;
  }

  .group-member-button-wrapper {
    padding-left: 0;

    .group-member-button {
      height: calc(1.5em + 0.75rem + 5px);
      border-radius: 6px;
      width: 100%;
    }
  }

  .hideGroupEvents, .showGroupEvents {
    margin-bottom: 1rem;
  }
}
