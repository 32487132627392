.genericModal {
  position: fixed;
  text-align: left;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modalDiv {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
  }

  .modalContent {
    max-width: 800px;
    position: relative;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
    background-color: #fefefe;
    margin: 1.75rem auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  .modalClose {
    position: absolute;
    top: 0;
    right: 1%;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }

  .modalClose:hover,
  .modalClose:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}
