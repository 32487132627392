@import 'src/vars.scss';

.CompetitorCard {
  .list-group-item {
    padding-top: 5px;
    padding-bottom: 5px;

    &.list-item-header {
      background-color: $baseBackgroundColor;
      color: white;
    }

    &.list-item-alternate {
      color: white;
      background-color: gray;
    }
  }

  .competitor-name {
    margin: 0;
  }
}

.CompetitorCard-left {
  padding-right: 0;
  text-align: center;

  img {
    max-width: 100px;
  }
}
