.Ring {
  padding: 5px;
  background-color: #c5c5c5;
  width: 275px;
  min-width: 275px;
  margin-right: 5px;
  height: fit-content;
  border-radius: 3px;

  .header {
    cursor: pointer;
    padding: 5px 5px 0 5px;

    .title {
      font-weight: bold;
    }

    .btn {
      padding: 0 6px;
      color: black;
    }
  }

  .subtitle {
    padding: 0 5px 10px 5px;
    font-size: 0.8rem;
  }

  .add-event-button {
    color: #576078;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: #d7d9de;
      color: black;
    }
  }

  .create-event {
    .close {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
}
