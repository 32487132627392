.AllResults {
  .video-link {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
  }

  .claimed-checkbox {
    height: 30px;
    min-width: 100%;
  }
}
