.sideNav-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(47, 53, 58);
  position: relative;
  flex: 1 0 auto;
  width: 15rem;

  .sideNav-item,
  .sideNav-title {
    text-align: left;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    font-weight: bold;
    color: white;

    &.Logout {
      color: #fcff00;
      display: block;
    }
  }

  &.collapsed {
    width: 3rem;

    .chevron {
      width: 100%;
      text-align: center;
    }
  }

  .sideNav-icon {
    margin-right: 10px;

    &:before {
      text-align: center;
      min-width: 20px;
    }
  }

  .sideNav-item:hover,
  .sideNav-item-selected {
    cursor: pointer;
    background-color: #20a8d8;
  }
}
