.nanduHeader {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #3c3b3b;
}
.nanduHeader .content-width {
  margin: auto;
  max-width: 1086px;
}
.nanduHeader #actions {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 64px;
  top: 0;
  left: 0;
  right: 0;
}
.nanduHeader .icon {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 50px;
  font-size: 20px;
  color: white;
  position: relative;
  cursor: pointer;
}
.nanduHeader .icon:hover  {
  background-color: #f9f9f9;
  color: black;
}
.nanduHeader .icon:hover .menu-display {
  display: flex;
}
.nanduHeader .spacer {
  flex: 1;
}
.nanduHeader .headerTitle {
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  padding: 0 0 21px 20px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  color: #fff;
}
.nanduHeader .scoresMenu {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 64px;
}
@keyframes blink {  
  0% { background-color: #039be5; }
  100% { background-color: #3c3b3b; }
}
@-webkit-keyframes blink {
  0% { background-color: #039be5; }
  100% { background-color: #3c3b3b; }
}
.nanduHeader .blink {
  -webkit-animation: blink 2s linear infinite;
  -moz-animation: blink 2s linear infinite;
  animation: blink 2s linear infinite;
}
.nanduHeader .appActions {
  max-width: 100vw;
  display: none;
  position: absolute;
  white-space: nowrap;
  top: 64px;
  right: 0;
  font-size: 16px;
  background-color: #f9f9f9;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.nanduHeader .action {
  min-width: 150px;
  padding: 13px;
  text-decoration: none;
  color: black;
}
.nanduHeader .action:not(:last-child) {
  border-bottom: 1px solid #F1F1EF;
}
.nanduHeader .action:hover {
  background-color: #039be5;
  color: white;
}