@import 'src/vars.scss';

.Accordion {
  .name {
    margin-bottom: 0;
    display: inline-block;
  }

  .card-header {
    background-color: $baseBackgroundColor;
    color: $base;
    cursor: pointer;
  }

  .nav-link {
    color: black;
  }
}
