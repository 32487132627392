.notFound {
  padding: 100px 0;
  max-width: 1086px;
  text-align: center;
  margin: auto;
}
.notFound .notFoundMessage {
  margin: auto;
  max-width: 600px;
}
.notFound .notFoundMessage a {
  color: blue;
  text-decoration: underline;
}