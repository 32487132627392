.JudgingHome {
  min-height: 100vh;
  display: flex;

  .content {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    padding: 4px;
    padding-bottom: 3rem;
    padding-top: 4.5rem;
    flex-basis: 100%;
    flex-direction: column;
    width: 0;
    min-width: 0;
    max-width: 100%;
    min-height: 1px;
  }

  .form-check {
    margin-top: 0.5rem;

    h5 {
      margin: 0;
    }
  }
}
