.LotStick {
  & .stick {
    width: 220px;
    height: 40px;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.5);
    background: sienna;
    background: #edd2b0;
    background: radial-gradient(
      #edd2b0 0%,
      #e9cba5 3%,
      #f6d8b2 9%,
      #eed3b0 13%,
      #f3d5af 17%,
      #f5dbb8 20%,
      #efd0a6 24%,
      #f2d4ae 28%,
      #e9cba5 30%,
      #f2d4ae 33%,
      #eeca9d 36%,
      #e6c7a1 41%,
      #f4d6b0 46%,
      #f2d4ae 50%,
      #efd0a9 54%,
      #f3d5af 59%,
      #f2d4ae 62%,
      #e9cba5 66%,
      #e9cba5 69%,
      #efd0a6 74%,
      #f4d6b0 77%,
      #e9cba5 82%,
      #e9cba5 89%,
      #efcfa4 92%,
      #f2d4ae 96%
    );
    background-size: 80px 800px;
    background-position: -20px -120px;
  }
}
