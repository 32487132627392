.DraggableTableItem {
  &.selected {
    background-color: orange;

    &:hover {
      background-color: rgba(255, 165, 0, 0.75);
    }
  }

  &.insertLineAbove {
    border-top: 4px solid orange;
  }

  &.insertLineBelow {
    border-bottom: 4px solid orange;
  }
}
