.pdfActions {
  max-width: 100vw;
  display: none;
  position: absolute;
  white-space: nowrap;
  top: 64px;
  right: 0;
  font-size: 14px;
  background-color: #f9f9f9;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.pdfActions .action, .pdfActions .warning {
  min-width: 150px;
  text-decoration: none;
  padding: 13px;
}
.pdfActions .action:not(:last-child), .pdfActions .warning {
  border-bottom: 1px solid #F1F1EF;
}
.pdfActions .action:hover {
  background-color: #039be5;
  color: white;
}
.pdfActions .warning {
  color: #c50e0e;
  white-space: normal;
  min-width: 240px;
  cursor: default;
}